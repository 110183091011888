<template>
  <b-modal
    :id="`modal-delete-employee-${userData.id}`"
    centered
    title="Mitarbeiter löschen"
    ok-title="Löschen"
    ok-variant="danger"
    cancel-title="Abbrechen"
    cancel-variant="outline-secondary"
    @ok="deleteEmployee"
  >
    <p>
      Sind Sie sicher, dass Sie den Mitarbeiter
      <b>{{ userData.Vorname }} {{ userData.Nachname }}</b> löschen wollen?
    </p>
    <p>
      Bestätigen Sie dazu bitte den Namen des Mitarbeiters und klicken Sie
      anschließend auf "Löschen".
    </p>
    <b-form-group
      label="Mitarbeiter bestätigen"
      label-for="mitarbeiter-bestaetigen"
    >
      <b-form-input
        id="mitarbeiter-bestaetigen"
        v-model="controlName"
        name="mitarbeiter-bestaetigen"
        placeholder="Mitarbeiter"
      />
      <small v-if="employeeNameMatches === false" class="text-danger"
        >Die Eingabe entspricht nicht dem Namen des Mitarbeiters</small
      >
    </b-form-group>
  </b-modal>
</template>

<script>
import { BModal, BFormInput, BFormGroup } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      controlName: null,
      employeeNameMatches: null,
    };
  },
  watch: {
    controlName(newValue) {
      if (newValue === null || newValue === '') {
        this.employeeNameMatches = null;
        return;
      }
      if (newValue === `${this.userData.Vorname} ${this.userData.Nachname}`) {
        this.employeeNameMatches = true;
        return;
      }
      this.employeeNameMatches = false;
    },
  },
  methods: {
    async deleteEmployee(modalEvent) {
      modalEvent.preventDefault();

      if (!this.employeeNameMatches) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Eingabe entspricht nicht dem Mitarbeiternamen!`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `Die Eingabe entspricht nicht dem Namen des Mitarbeiters. Korrigieren Sie bitte die Eingabe.`,
          },
        });
        return;
      }
      await this.$http
        .delete(`/users/${this.userData.User.id}`)
        .then(async () => {
          if (this.userData.User.avatar?.id) {
            await this.$http
              .delete(`/upload/files/${this.userData.User.avatar.id}`)
              .catch(err => console.log(err.response.data.error.message));
          }

          await this.$http
            .delete(`/mitarbeiters/${this.userData.id}`)
            .catch(err => console.log(err.response.data.error.message));

          // Emit Event to Parent Component
          this.$emit('userDeleted');

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Mitarbeiter gelöscht`,
              icon: 'DeleteIcon',
              variant: 'success',
              text: `Der Mitarbeiter ${this.userData.Vorname} ${this.userData.Nachname} wurde erfolgreich gelöscht.`,
            },
          });

          if (this.$route.name !== 'mitarbeiter') {
            this.$router.push({ name: 'mitarbeiter' });
          }

          // Close Modal
          this.$nextTick(() => {
            this.$bvModal.hide(`modal-delete-employee-${this.userData.id}`);
          });
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Löschen fehlgeschlagen!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Das Löschen des Mitarbeiters ist fehlgeschlagen. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
            },
          });
          console.log(err.response.data.error.message);
        });
    },
  },
};
</script>
