<template>
  <b-card no-body>
    <!-- title -->
    <b-card-header class="pb-0">
      <b-card-title>{{ title }}</b-card-title>
    </b-card-header>
    <!--/ title -->

    <b-card-body>
      <b-row>
        <b-col sm="2" class="d-flex flex-column flex-wrap text-center">
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ totalEmployees }}
          </h1>
          <b-card-text>Mitarbeiter</b-card-text>
        </b-col>

        <!-- chart -->
        <b-col sm="10" class="d-flex justify-content-center">
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="400"
            :options="employeeRadialBar.chartOptions"
            :series="dataSeries"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">Frei</b-card-text>
          <span class="font-large-1 font-weight-bold">{{ freeEmployees }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">Im Auftrag</b-card-text>
          <span class="font-large-1 font-weight-bold">{{ busyEmployees }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">Krank</b-card-text>
          <span class="font-large-1 font-weight-bold">{{ illEmployees }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BRow,
  BCol,
} from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';
import store from '@/store';

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      totalEmployees: 0,
      freeEmployees: 0,
      busyEmployees: 0,
      illEmployees: 0,
      title: 'Mitarbeiter',
      employeeRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.success],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Mitarbeiter im Auftrag'],
        },
      },
    };
  },

  computed: {
    dataSeries() {
      if (this.busyEmployees !== 0 || this.totalEmployees !== 0) {
        return [
          Math.floor((this.busyEmployees / this.totalEmployees) * 100 * 100) /
            100,
        ];
      } else {
        return [0];
      }
    },
  },
  async created() {
    await this.getEmployeeStatus();
  },
  methods: {
    async getEmployeeStatus() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: 1,
            pageSize: 500,
          },
          fields: ['Verfuegbar'],
        },
        {
          encodeValuesOnly: true,
        }
      );

      let employeeItems = [];

      await this.$http
        .get(`/mitarbeiters?${query}`)
        .then(response => {
          employeeItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.totalEmployees = employeeItems.length;
      employeeItems.forEach(employee => {
        if (employee.Verfuegbar === 'Verfuegbar') this.freeEmployees += 1;
        if (employee.Verfuegbar === 'Im_Auftrag') this.busyEmployees += 1;
        if (employee.Verfuegbar === 'Krank') this.illEmployees += 1;
      });
    },
  },
};
</script>
