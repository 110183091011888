<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Monatsübersicht - Aufträge</b-card-title>
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="400"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="customersPie.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardBody } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      orderData: {},
      customersPie: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [
            'Warte auf Freigabe',
            'Bestätigt',
            'Bereit',
            'Laufend',
            'Beendet',
            'Ruht',
            'Abgebrochen',
          ],
          dataLabels: {
            enabled: true,
          },
          legend: { show: true, position: 'bottom' },
          stroke: {
            width: 4,
          },
          colors: [
            $themeColors.secondary,
            $themeColors.info,
            $themeColors.success,
            $themeColors.dark,
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
    };
  },
  async created() {
    await this.getOrderData();
    this.setOrderStates();
  },
  methods: {
    setOrderStates() {
      const states = {
        Schwebend: 0,
        Bestaetigt: 0,
        Bereit: 0,
        Laufend: 0,
        Beendet: 0,
        Ruht: 0,
        Abgebrochen: 0,
      };

      for (let i = 0; i < this.orderData.length; i++) {
        switch (true) {
          case this.orderData[i].Status === 'Schwebend':
            states.Schwebend += 1;
            break;
          case this.orderData[i].Status === 'Bestaetigt':
            states.Bestaetigt += 1;
            break;
          case this.orderData[i].Status === 'Bereit':
            states.Bereit += 1;
            break;
          case this.orderData[i].Status === 'Laufend':
            states.Laufend += 1;
            break;
          case this.orderData[i].Status === 'Beendet':
            states.Beendet += 1;
            break;
          case this.orderData[i].Status === 'Ruht':
            states.Ruht += 1;
            break;
          case this.orderData[i].Status === 'Abgebrochen':
            states.Abgebrochen += 1;
            break;
          default:
            break;
        }
      }
      this.customersPie.series = Object.values(states);
    },
    async getOrderData() {
      const firstOfMonth = `${new Date(Date.now())
        .toISOString()
        .substring(0, 8)}01`;

      // Fetch Order Data
      const qs = require('qs');
      const query = qs.stringify(
        {
          fields: ['updatedAt', 'Status'],
          filters: {
            updatedAt: {
              $gte: firstOfMonth,
              // $gte: '2022-01-01',
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      await this.$http
        .get(`/auftraege?${query}`)
        .then(response => {
          this.orderData = response.data.data;
        })
        .catch(err => console.log(err.response.data.error.message));
    },
  },
};
</script>
