<template>
  <b-modal
    id="modal-add-employee"
    centered
    title="Mitarbeiter hinzufügen"
    ok-title="Hinzufügen"
    cancel-title="Abbrechen"
    cancel-variant="outline-secondary"
    @ok="addEmployee"
    @cancel="initializeEmployeeData"
    @close="initializeEmployeeData"
  >
    <!-- Form -->
    <validation-observer ref="registerEmployeeForm" #default="{ invalid }">
      <b-form class="auth-register-form mt-2" @submit.prevent="register">
        <b-card-title class="mb-1">Anmeldedaten</b-card-title>

        <!-- Username -->
        <b-form-group label="Benutzername*" label-for="register-username">
          <validation-provider
            #default="{ errors }"
            name="Username"
            vid="username"
            rules="required|min:5"
          >
            <b-form-input
              id="register-username"
              v-model="userData.username"
              name="register-username"
              :state="errors.length > 0 ? false : null"
              placeholder="maxmustermann"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="userIdNotUnique" class="text-danger"
              >Benutzername bereits vergeben</small
            >
          </validation-provider>
        </b-form-group>

        <!-- Email -->
        <b-form-group label="Email*" label-for="register-email">
          <validation-provider
            #default="{ errors }"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="register-email"
              v-model="userData.email"
              name="register-email"
              :state="errors.length > 0 ? false : null"
              placeholder="mitarbeiter@temporatio.de"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="emailNotUnique" class="text-danger"
              >Email-Adresse bereits vergeben</small
            >
          </validation-provider>
        </b-form-group>

        <!-- Passwort -->
        <b-form-group label-for="register-password" label="Passwort*">
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="password"
            rules="required|min:6"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                v-model="userData.password"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <hr />

        <b-card-title class="mb-1">Grunddaten</b-card-title>

        <!-- Avatar-Bild -->
        <b-form-group label-for="picture-upload" label="Benutzerbild hochladen">
          <div class="d-flex">
            <b-form-file
              id="picture-upload"
              v-model="image"
              accept="image/*"
              placeholder="Datei auswählen ..."
              drop-placeholder="Datei hierher ziehen ..."
            />
            <b-button
              v-if="hasImage"
              variant="danger"
              class="ml-1 text-nowrap"
              @click="clearImage"
              >Bild löschen</b-button
            >
          </div>
          <b-img
            v-if="hasImage"
            :src="imageSrc"
            class="mt-1 rounded-circle mx-auto avatar-preview"
            fluid
            block
          ></b-img>
        </b-form-group>

        <b-row>
          <b-col md="6">
            <!-- Vorname -->
            <b-form-group label="Vorname*" label-for="register-vorname">
              <validation-provider
                #default="{ errors }"
                name="Vorname"
                vid="vorname"
                rules="required"
              >
                <b-form-input
                  id="register-vorname"
                  v-model="employeeData.Vorname"
                  name="register-vorname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Max"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <!-- Nachname -->
            <b-form-group label="Nachname*" label-for="register-nachname">
              <validation-provider
                #default="{ errors }"
                name="Nachname"
                vid="nachname"
                rules="required"
              >
                <b-form-input
                  id="register-nachname"
                  v-model="employeeData.Nachname"
                  name="register-nachname"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mustermann"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Geburtstag -->
        <b-form-group label="Geburtstag" label-for="Geburtstag">
          <b-form-datepicker
            id="register-geburtstag"
            v-model="employeeData.Geburtstag"
            v-bind="{
              labelPrevDecade: 'Vorheriges Jahrzehnt',
              labelPrevYear: 'Vorheriges Jahr',
              labelPrevMonth: 'Vorheriger Monat',
              labelCurrentMonth: 'Aktueller Monat',
              labelNextMonth: 'Nächster Monat',
              labelNextYear: 'Nächstes Jahr',
              labelNextDecade: 'Nächstes Jahrzehnt',
              labelToday: 'Heute',
              labelSelected: 'Ausgewähltes Datum',
              labelNoDateSelected: 'Kein Datum gewählt',
              labelCalendar: 'Kalender',
              labelNav: 'Kalendernavigation',
              labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
            }"
            :start-weekday="1"
            show-decade-nav
            locale="de"
            aria-controls="register-geburtstag"
            placeholder="Datum auswählen"
          />
        </b-form-group>
        <b-row>
          <b-col md="6">
            <!-- Telefon -->
            <b-form-group label="Telefon" label-for="register-telefon">
              <b-form-input
                id="register-telefon"
                v-model="employeeData.Kontakt.Telefon"
                name="register-telefon"
                placeholder="12345 12345678"
              />
            </b-form-group>
          </b-col>
          <!-- Mobil -->
          <b-col md="6">
            <b-form-group label="Mobil" label-for="register-mobil">
              <b-form-input
                id="register-mobil"
                v-model="employeeData.Kontakt.Mobil"
                name="register-mobil"
                placeholder="12345 12345678"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-card-title class="mb-1">Anschrift</b-card-title>
        <b-row>
          <b-col md="6">
            <!-- Straße -->
            <b-form-group label="Straße" label-for="register-strasse">
              <b-form-input
                id="register-strasse"
                v-model="employeeData.Anschrift.Strasse"
                name="register-strasse"
                placeholder="Musterstraße"
              />
            </b-form-group>
          </b-col>
          <!-- Hausnummer -->
          <b-col md="6">
            <b-form-group label="Hausnummer" label-for="register-hausnummer">
              <b-form-input
                id="register-hausnummer"
                v-model="employeeData.Anschrift.Hausnummer"
                name="register-hausnummer"
                placeholder="26"
              />
            </b-form-group>
          </b-col>
          <!-- Adresszusatz -->
          <b-col md="6">
            <b-form-group
              label="Adresszusatz"
              label-for="register-adresszusatz"
            >
              <b-form-input
                id="register-adresszusatz"
                v-model="employeeData.Anschrift.Zusatz"
                name="register-adresszusatz"
                placeholder="Postfach"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <!-- PLZ -->
            <b-form-group label="PLZ" label-for="register-plz">
              <b-form-input
                id="register-plz"
                v-model="employeeData.Anschrift.PLZ"
                name="register-plz"
                type="number"
                placeholder="12345"
              />
            </b-form-group>
          </b-col>
          <!-- Stadt -->
          <b-col md="6">
            <b-form-group label="Stadt" label-for="register-stadt">
              <b-form-input
                id="register-stadt"
                v-model="employeeData.Anschrift.Stadt"
                name="register-stadt"
                placeholder="Heidelberg"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <b-card-title class="mb-1">Anstellung</b-card-title>
        <!-- Mitarbeiternummer -->
        <b-form-group
          label="Mitarbeiternummer*"
          label-for="register-mitarbeiternummer"
        >
          <validation-provider
            #default="{ errors }"
            name="Mitarbeiternummer"
            vid="mitarbeiternummer"
            rules="required|min:5"
          >
            <b-form-input
              id="register-mitarbeiternummer"
              v-model="employeeData.Mitarbeiternummer"
              name="register-mitarbeiternummer"
              placeholder="12345"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small v-if="employeeIdNotUnique" class="text-danger"
              >Mitarbeiternummer bereits vergeben</small
            >
          </validation-provider>
        </b-form-group>

        <!-- Anstellungsart -->
        <b-form-group
          label="Anstellungsart"
          label-for="register-anstellungsart"
        >
          <v-select
            v-model="employeeData.Anstellungsart"
            id="register-anstellungsart"
            :reduce="anstellungsart => anstellungsart.value"
            placeholder="Anstellungsart auswählen"
            :options="[
              { label: 'Fest', value: 'Fest' },
              { label: 'Teilzeit', value: 'Teilzeit' },
              { label: 'Mini', value: 'Mini' },
              { label: 'Frei', value: 'Frei' },
            ]"
          />
        </b-form-group>

        <!-- Stundensatz -->
        <b-form-group label="Stundensatz" label-for="register-stundensatz">
          <b-input-group append="€ pro Stunde">
            <b-form-input
              id="register-stundensatz"
              v-model="employeeData.Stundensatz"
              name="register-stundensatz"
              type="number"
              placeholder="30"
            />
          </b-input-group>
        </b-form-group>
        <!-- Kenntnisse -->
        <b-form-group label="Kenntnisse" label-for="register-kenntnisse">
          <div class="d-flex" id="register-kenntnisse">
            <b-form-checkbox
              id="register-qualitaetssicherung"
              class="w-50"
              v-model="employeeData.Kenntnisse.Qualitaetssicherung"
              name="register-qualitaetssicherung"
              >Qualitätssicherung</b-form-checkbox
            >
            <b-form-checkbox
              id="register-messmittel"
              class="w-50"
              v-model="employeeData.Kenntnisse.Messmittel"
              name="register-messmittel"
              >Messmittel</b-form-checkbox
            >
          </div>
        </b-form-group>

        <!-- Arbeitserlaubnis -->
        <b-form-group
          label="Arbeitserlaubnis"
          label-for="register-arbeitserlaubnis"
        >
          <div class="d-flex">
            <b-form-checkbox
              id="register-arbeitserlaubnis"
              class="w-100"
              v-model="employeeData.Arbeitserlaubnis"
              name="register-arbeitserlaubnis"
              >Hat eine Arbeitserlaubnis</b-form-checkbox
            >
          </div>
        </b-form-group>

        <!-- Arbeitserlaubnis bis -->
        <b-form-group
          label="Arbeitserlaubnis bis"
          label-for="register-arbeitserlaubnis-bis"
          v-if="employeeData.Arbeitserlaubnis"
        >
          <b-form-datepicker
            id="register-arbeitserlaubnis-bis"
            v-model="employeeData.Gueltigkeit_Arbeitserlaubnis"
            v-bind="{
              labelPrevDecade: 'Vorheriges Jahrzehnt',
              labelPrevYear: 'Vorheriges Jahr',
              labelPrevMonth: 'Vorheriger Monat',
              labelCurrentMonth: 'Aktueller Monat',
              labelNextMonth: 'Nächster Monat',
              labelNextYear: 'Nächstes Jahr',
              labelNextDecade: 'Nächstes Jahrzehnt',
              labelToday: 'Heute',
              labelSelected: 'Ausgewähltes Datum',
              labelNoDateSelected: 'Kein Datum gewählt',
              labelCalendar: 'Kalender',
              labelNav: 'Kalendernavigation',
              labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
            }"
            :start-weekday="1"
            show-decade-nav
            locale="de"
            aria-controls="register-arbeitserlaubnis-bis"
            placeholder="Datum auswählen"
          />
        </b-form-group>

        <!-- Bewertung -->
        <b-form-group label="Bewertung" label-for="register-bewertung">
          <b-form-radio-group
            id="register-bewertung"
            class="d-flex justify-content-between"
            v-model="employeeData.Bewertung"
            :options="[
              { text: '0', value: 0 },
              { text: '1', value: 1 },
              { text: '2', value: 2 },
              { text: '3', value: 3 },
              { text: '4', value: 4 },
              { text: '5', value: 5 },
              { text: '6', value: 6 },
            ]"
            name="radio-validation"
          >
          </b-form-radio-group>
        </b-form-group>
        <hr />

        <b-card-title class="mb-1">Verfügbarkeit</b-card-title>
        <!-- Verfügbarkeit -->
        <div class="checkbox-group">
          <b-form-checkbox
            id="register-fruehschicht"
            v-model="employeeData.Verfuegbarkeit.Fruehschicht"
            name="register-fruehschicht"
            >Frühschicht</b-form-checkbox
          >
          <b-form-checkbox
            id="register-spaetschicht"
            v-model="employeeData.Verfuegbarkeit.Spaetschicht"
            name="register-spaetschicht"
            >Spätschicht</b-form-checkbox
          >
          <b-form-checkbox
            id="register-nachtschicht"
            v-model="employeeData.Verfuegbarkeit.Nachtschicht"
            name="register-nachtschicht"
            >Nachtschicht</b-form-checkbox
          >
          <b-form-checkbox
            id="register-montag"
            v-model="employeeData.Verfuegbarkeit.Montag"
            name="register-montag"
            >Montag</b-form-checkbox
          >
          <b-form-checkbox
            id="register-dienstag"
            v-model="employeeData.Verfuegbarkeit.Dienstag"
            name="register-dienstag"
            >Dienstag</b-form-checkbox
          >
          <b-form-checkbox
            id="register-mittwoch"
            v-model="employeeData.Verfuegbarkeit.Mittwoch"
            name="register-mittwoch"
            >Mittwoch</b-form-checkbox
          >
          <b-form-checkbox
            id="register-donnerstag"
            v-model="employeeData.Verfuegbarkeit.Donnerstag"
            name="register-donnerstag"
            >Donnerstag</b-form-checkbox
          >
          <b-form-checkbox
            id="register-freitag"
            v-model="employeeData.Verfuegbarkeit.Freitag"
            name="register-freitag"
            >Freitag</b-form-checkbox
          >
          <b-form-checkbox
            id="register-samstag"
            v-model="employeeData.Verfuegbarkeit.Samstag"
            name="register-samstag"
            >Samstag</b-form-checkbox
          >
          <b-form-checkbox
            id="register-sonntag"
            v-model="employeeData.Verfuegbarkeit.Sonntag"
            name="register-sonntag"
            >Sonntag</b-form-checkbox
          >
        </div>

        <!-- Mobilität -->
        <b-form-group label="Mobilität" label-for="register-mobilitaet">
          <v-select
            v-model="employeeData.Mobilitaet"
            id="register-mobilitaet"
            :reduce="mobilitaet => mobilitaet.value"
            placeholder="Mobilität auswählen"
            :options="[
              { label: 'PKW-Führerschein', value: 'PKW-Fuehrerschein' },
              { label: 'LKW-Führerschein', value: 'LKW-Fuehrerschein' },
              { label: 'Öffentliche', value: 'Oeffentliche' },
            ]"
          />
        </b-form-group>
        <hr />

        <b-card-title class="mb-1">Sonstiges</b-card-title>
        <!-- Sonstiges -->
        <b-form-group
          label="Sonstige Informationen"
          label-for="register-sonstiges"
        >
          <b-form-textarea
            id="register-sonstiges"
            v-model="employeeData.Sonstiges"
            rows="3"
            name="register-sonstiges"
            placeholder="Sonstige Informationen"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import {
  BModal,
  BRow,
  BCol,
  BCardTitle,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BFormFile,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

localize('de', de);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCardTitle,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BFormDatepicker,
    BFormFile,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      image: null,
      imageSrc: null,
      employeeData: {},
      userData: { username: null, email: null, password: null },
      // validation
      required,
      email,
      employeeIdNotUnique: false,
      userIdNotUnique: false,
      emailNotUnique: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    hasImage() {
      return !!this.image;
    },
  },
  watch: {
    'employeeData.Mitarbeiternummer': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Mitarbeiternummer: {
                $eq: this.employeeData.Mitarbeiternummer,
              },
            },
            fields: ['id', 'Mitarbeiternummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/mitarbeiters?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.employeeIdNotUnique = true;
            } else {
              this.employeeIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.username': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              username: {
                $eq: this.userData.username,
              },
            },
            fields: ['id', 'username'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.userIdNotUnique = true;
            } else {
              this.userIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.email': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              email: {
                $eq: this.userData.email,
              },
            },
            fields: ['id', 'email'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.emailNotUnique = true;
            } else {
              this.emailNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  beforeMount() {
    this.initializeEmployeeData();
  },
  methods: {
    async addEmployee(modalEvent) {
      modalEvent.preventDefault();

      this.$refs.registerEmployeeForm.validate().then(async isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        // Register User
        await this.$http
          .post('/users', {
            username: this.userData.username,
            email: this.userData.email,
            password: this.userData.password,
            role: 3,
          })
          .then(response => {
            const userID = response.data.id;
            return userID;
          })
          .then(async userID => {
            // Register Image
            if (this.image) {
              const formData = new FormData();
              formData.append('field', 'avatar');
              formData.append('ref', 'plugin::users-permissions.user');
              formData.append('refId', userID);
              formData.append('files', this.image, this.image.name);

              await this.$http
                .post('/upload', formData)
                .catch(err => console.log(err.response.data.error.message));
            }

            // Register Employee
            this.employeeData.User = +userID;
            await this.$http
              .post(`/mitarbeiters`, { data: this.employeeData })
              .then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Mitarbeiter angelegt`,
                    icon: 'SaveIcon',
                    variant: 'success',
                    text: `Der Mitarbeiter ${this.employeeData.Vorname} ${this.employeeData.Nachname} wurde erfolgreich gespeichert.`,
                  },
                });

                // Clear Employee Data
                this.initializeEmployeeData();

                // Emit Event to Parent Component
                this.$emit('newUserAdded');

                // Close Modal
                this.$nextTick(() => {
                  this.$bvModal.hide(`modal-add-employee`);
                });
              })
              .catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Anlegen des Mitarbeiters fehlgeschlagen!`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: `Der Mitarbeiter konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
                  },
                });
                console.log(err.response.data.error.message);
              });
          })
          .catch(err => {
            console.log(err.response.data.error.message);
          });
      });
    },

    // Clear Image
    clearImage() {
      this.image = null;
    },

    // Clear Customer Data
    initializeEmployeeData() {
      this.userData = {
        username: null,
        email: null,
        password: null,
      };
      this.employeeData = {
        Vorname: null,
        Nachname: null,
        Mitarbeiternummer: null,
        Geburtstag: null,
        Anstellungsart: null,
        Bewertung: 0,
        Stundensatz: null,
        Mobilitaet: null,
        Sonstiges: null,
        Verfuegbar: 'Verfuegbar',
        Arbeitserlaubnis: false,
        Verfuegbarkeit: {
          Fruehschicht: false,
          Spaetschicht: false,
          Nachtschicht: false,
          Montag: false,
          Dienstag: false,
          Mittwoch: false,
          Donnerstag: false,
          Freitag: false,
          Samstag: false,
          Sonntag: false,
        },
        Kontakt: { Telefon: null, Mobil: null, Fax: null },
        Anschrift: {
          Strasse: null,
          Hausnummer: null,
          Zusatz: null,
          PLZ: null,
          Stadt: null,
        },
        Kenntnisse: { Qualitaetssicherung: false, Messmittel: false },
        User: null,
      };
      this.clearImage();
    },
  },
};
</script>

<style scoped>
.checkbox-group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checkbox-group .custom-checkbox {
  width: 10rem;
  margin-bottom: 0.5rem;
}
.avatar-preview {
  aspect-ratio: 1;
  object-fit: cover;
}
</style>
