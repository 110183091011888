<template>
  <b-modal
    :id="`modal-delete-order-${orderData.id}`"
    centered
    title="Auftrag löschen"
    ok-title="Löschen"
    ok-variant="danger"
    cancel-title="Abbrechen"
    cancel-variant="outline-secondary"
    @ok="deleteOrder"
  >
    <p>
      Sind Sie sicher, dass Sie den Auftrag
      <b>#{{ orderData.Auftragsnummer }}</b> löschen wollen?
    </p>
    <p>
      Bestätigen Sie dazu bitte die Auftragsnummer und klicken Sie anschließend
      auf "Löschen".
    </p>
    <b-form-group
      label="Auftragsnummer bestätigen"
      label-for="auftragsnummer-bestaetigen"
    >
      <b-form-input
        id="auftragsnummer-bestaetigen"
        v-model="controlNumber"
        name="auftragsnummer-bestaetigen"
        placeholder="Auftragsnummer"
      />
      <small v-if="ordernumberMatches === false" class="text-danger"
        >Die Eingabe entspricht nicht der Auftragsnummer</small
      >
    </b-form-group>
  </b-modal>
</template>

<script>
import { BModal, BFormInput, BFormGroup } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      controlNumber: null,
      ordernumberMatches: null,
    };
  },
  watch: {
    controlNumber(newValue) {
      if (newValue === null || newValue === '') {
        this.ordernumberMatches = null;
        return;
      }
      if (newValue === this.orderData.Auftragsnummer) {
        this.ordernumberMatches = true;
        return;
      }
      this.ordernumberMatches = false;
    },
  },
  methods: {
    async deleteOrder(modalEvent) {
      modalEvent.preventDefault();

      if (!this.ordernumberMatches) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Eingabe entspricht nicht der Auftragsnummer!`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `Die Eingabe entspricht nicht der Auftragsnummer. Korrigieren Sie bitte die Eingabe.`,
          },
        });
        return;
      }
      await this.$http
        .delete(`/auftraege/${this.orderData.id}`)
        .then(() => {
          // Emit Event to Parent Component
          this.$emit('orderDeleted');
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Auftrag gelöscht`,
              icon: 'DeleteIcon',
              variant: 'success',
              text: `Der Auftrag #${this.orderData.Auftragsnummer} wurde erfolgreich gelöscht.`,
            },
          });

          if (this.$route.name !== 'auftraege') {
            this.$router.push({ name: 'auftraege' });
          }

          // Close Modal
          this.$nextTick(() => {
            this.$bvModal.hide(`modal-delete-order-${this.orderData.id}`);
          });
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Löschen fehlgeschlagen!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Das Löschen des Auftrags ist fehlgeschlagen. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
            },
          });
          console.log(err.response.data.error.message);
        });
    },
  },
};
</script>
