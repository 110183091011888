<template>
  <b-card title="Aufträge" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="ListIcon" />
        <b-card-title class="ml-25"> Aufträge </b-card-title>
      </div>
    </b-card-header>
    <div class="mx-2 mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mb-0">Zeige</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label class="mb-0">Aufträge</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <label class="mb-0 mr-50 text-nowrap" for="status-selection"
              >Status filtern:</label
            >
            <v-select
              id="status-selection"
              v-model="filters.statusFilter"
              label="title"
              :options="[
                { title: 'Warte auf Freigabe', value: 'Schwebend' },
                { title: 'Bestätigt', value: 'Bestaetigt' },
                { title: 'Bereit', value: 'Bereit' },
                { title: 'Laufend', value: 'Laufend' },
                { title: 'Beendet', value: 'Beendet' },
                { title: 'Abgebrochen', value: 'Abgebrochen' },
                { title: 'Ruht', value: 'Ruht' },
              ]"
            />
            <b-form-input
              v-model="filters.searchQuery"
              class="d-inline-block mx-1"
              placeholder="Suchen..."
            />
            <b-button variant="primary" v-b-modal.modal-add-order-table>
              <span class="text-nowrap">Auftrag hinzufügen</span>
            </b-button>
            <add-order-modal @newOrderAdded="refetchTable" uniqueName="table" />
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        :key="tableKey"
        :items="orderItemsProvider"
        :fields="fields"
        :per-page="0"
        :current-page="currentPage"
        :filter="filters"
        sort-by="Auftragsnummer"
        sort-direction="asc"
        :sort-desc="true"
        striped
        responsive
        class="mb-0"
      >
        <!-- Column: Actions -->
        <template #cell(Aktionen)="row">
          <div class="text-nowrap">
            <feather-icon
              :id="`order-row-${row.item.id}-preview-icon`"
              icon="EyeIcon"
              size="20"
              class="mr-50 cursor-pointer text-primary"
              @click="row.toggleDetails"
            />
            <b-tooltip
              title="Infos einblenden"
              class="cursor-pointer"
              :target="`order-row-${row.item.id}-preview-icon`"
            />
            <feather-icon
              :id="`order-row-${row.item.id}-details-icon`"
              icon="FileTextIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'auftrag-details',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Details"
              class="cursor-pointer"
              :target="`order-row-${row.item.id}-details-icon`"
            />
            <feather-icon
              :id="`order-row-${row.item.id}-edit-icon`"
              icon="EditIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'auftrag-bearbeiten',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Bearbeiten"
              class="cursor-pointer"
              :target="`order-row-${row.item.id}-edit-icon`"
            />
            <feather-icon
              :id="`order-row-${row.item.id}-delete-icon`"
              icon="TrashIcon"
              size="20"
              class="mx-50 cursor-pointer text-danger"
              v-b-modal:[`modal-delete-order-${row.item.id}`]
            />
            <b-tooltip
              title="Löschen"
              class="cursor-pointer"
              :target="`order-row-${row.item.id}-delete-icon`"
            />
            <delete-order-modal
              :orderData="row.item"
              @orderDeleted="refetchTable"
            />
          </div>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card class="details-card">
            <h4 class="mb-1">Allgemein</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Bestellnummer: </strong>#{{ row.item.Bestellnummer }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Startdatum: </strong
                >{{
                  new Date(
                    row.item.Auftragskonditionen.Startdatum
                  ).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="row.item.Auftragskonditionen.Startzeit"
              >
                <strong>Startzeit: </strong
                >{{ row.item.Auftragskonditionen.Startzeit.substr(0, 5) }}
                Uhr
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="
                  row.item.Auftragskonditionen.Enddatum >
                  row.item.Auftragskonditionen.Startdatum
                "
              >
                <strong>Enddatum: </strong
                >{{
                  new Date(
                    row.item.Auftragskonditionen.Enddatum
                  ).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Mitarbeiterbedarf: </strong
                >{{ row.item.Auftragskonditionen.Mitarbeiterbedarf }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Kunde</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Firma: </strong>{{ row.item.Kunde.Firma }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Telefon: </strong>{{ row.item.Kunde.Kontakt.Telefon }}
              </b-col>

              <b-col md="4" class="mb-1">
                <strong>Fax: </strong>{{ row.item.Kunde.Kontakt.Fax }}
              </b-col>
            </b-row>

            <!-- Ansprechpartner -->
            <b-row class="mx-0">
              <b-col md="12" class="mb-1">
                <b-card
                  class="mb-0 details-card"
                  :class="{ inactive: ansprechpartner.Inaktiv }"
                  v-for="ansprechpartner in getContactPerson(row.item)"
                  :key="ansprechpartner.id"
                >
                  <h5 class="mb-1"><b>Ansprechpartner</b></h5>
                  <b-row class="mx-1">
                    <b-col cols="4" class="mb-1">
                      <strong>Name: </strong>{{ ansprechpartner.Vorname }}
                      {{ ansprechpartner.Nachname }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Abteilung: </strong
                      >{{ ansprechpartner.Abteilung }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Position: </strong>{{ ansprechpartner.Position }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Email: </strong
                      ><a
                        :href="`mailto:${ansprechpartner.Email}`"
                        target="_blank"
                        >{{ ansprechpartner.Email }}</a
                      >
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Telefon: </strong
                      ><a :href="`tel:${ansprechpartner.Telefon}`">{{
                        ansprechpartner.Telefon
                      }}</a>
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Mobil: </strong
                      ><a :href="`tel:${ansprechpartner.Mobil}`">{{
                        ansprechpartner.Mobil
                      }}</a>
                    </b-col>
                    <b-col md="4" class="d-flex">
                      <strong>Inaktiv: </strong>
                      <b-form-checkbox
                        class="ml-1"
                        :checked="ansprechpartner.Inaktiv"
                        disabled
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>

            <!-- Auftragsort -->
            <b-row class="mx-0">
              <b-col md="12" class="mb-2">
                <b-card class="mb-0 details-card">
                  <h5 class="mb-1"><b>Auftragsort</b></h5>
                  <b-row class="align-items-center mx-1">
                    <b-col
                      cols="4"
                      class="mb-1"
                      v-if="row.item.Abweichender_Aufragsort"
                    >
                      <div class="mb-1">
                        <strong>Straße: </strong>
                        {{ row.item.Abweichender_Aufragsort.Strasse }}
                        {{ row.item.Abweichender_Aufragsort.Hausnummer }}
                      </div>
                      <div
                        class="mb-1"
                        v-if="row.item.Abweichender_Aufragsort.Zusatz"
                      >
                        <strong>Adresszusatz: </strong>
                        {{ row.item.Abweichender_Aufragsort.Zusatz }}
                      </div>
                      <div class="mb-1">
                        <strong>PLZ: </strong>
                        {{ row.item.Abweichender_Aufragsort.PLZ }}
                      </div>
                      <div>
                        <strong>Stadt: </strong>
                        {{ row.item.Abweichender_Aufragsort.Stadt }}
                      </div>
                    </b-col>
                    <b-col cols="4" class="mb-1" v-else>
                      <div class="mb-1">
                        <strong>Straße: </strong>
                        {{ row.item.Kunde.Anschrift.Strasse }}
                        {{ row.item.Kunde.Anschrift.Hausnummer }}
                      </div>
                      <div class="mb-1" v-if="row.item.Kunde.Anschrift.Zusatz">
                        <strong>Adresszusatz: </strong>
                        {{ row.item.Kunde.Anschrift.Zusatz }}
                      </div>
                      <div class="mb-1">
                        <strong>PLZ: </strong>
                        {{ row.item.Kunde.Anschrift.PLZ }}
                      </div>
                      <div>
                        <strong>Stadt: </strong>
                        {{ row.item.Kunde.Anschrift.Stadt }}
                      </div>
                    </b-col>
                    <b-col
                      cols="8"
                      class="mb-1"
                      v-if="row.item.Abweichender_Aufragsort"
                    >
                      <iframe
                        class="w-100 google-map"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="`https://maps.google.com/maps?&amp;q=${encodeURIComponent(
                          `${row.item.Abweichender_Aufragsort.Strasse} ${row.item.Abweichender_Aufragsort.Hausnummer}, ${row.item.Abweichender_Aufragsort.PLZ} ${row.item.Abweichender_Aufragsort.Stadt}`
                        )}&amp;output=embed`"
                      ></iframe>
                    </b-col>
                    <b-col cols="8" class="mb-1" v-else>
                      <iframe
                        class="w-100 google-map"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        :src="`https://maps.google.com/maps?&amp;q=${encodeURIComponent(
                          `${row.item.Kunde.Anschrift.Strasse} ${row.item.Kunde.Anschrift.Hausnummer}, ${row.item.Kunde.Anschrift.PLZ} ${row.item.Kunde.Anschrift.Stadt}`
                        )}&amp;output=embed`"
                      ></iframe>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Prüfpositionen</h4>
            <b-row class="mx-0">
              <b-col md="12" class="mb-1">
                <b-card
                  class="mb-1 details-card"
                  v-for="(pruefposition, pruefpositionIndex) in row.item
                    .Pruefpositionen"
                  :key="pruefposition.id"
                >
                  <h5 class="mb-1">
                    <b>Prüfposition {{ pruefpositionIndex + 1 }}</b>
                  </h5>
                  <b-row class="align-items-center mx-1">
                    <b-col cols="4" class="mb-1">
                      <strong>Beschreibung: </strong
                      >{{ pruefposition.Bezeichnung }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Bauteilgruppe: </strong
                      >{{ pruefposition.Bauteilgruppe }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Stückzahl: </strong>{{ pruefposition.Stueckzahl }}
                    </b-col>
                    <b-col cols="4" class="mb-1">
                      <strong>Status: </strong>
                      <b-badge
                        :variant="status[1][orderStatus(pruefposition.Status)]"
                      >
                        {{ status[0][orderStatus(pruefposition.Status)] }}
                      </b-badge>
                    </b-col>
                    <b-col
                      cols="4"
                      class="mb-1"
                      v-if="pruefposition.Folgelieferung_Pruefen"
                    >
                      <strong>Folgelieferung Prüfen: </strong>
                      <b-form-checkbox
                        class="d-inline-block"
                        :checked="pruefposition.Folgelieferung_Pruefen"
                        disabled
                      />
                    </b-col>
                    <b-col cols="12" class="mb-1" v-if="pruefposition.Anhaenge">
                      <strong>Detailbilder: </strong>
                      <b-row class="mt-50">
                        <b-col
                          cols="1"
                          v-for="(img, imgIndex) in pruefpositionenLightbox[
                            row.index
                          ].pruefpositionen[pruefpositionIndex]
                            .imgThumbnailUrls"
                          :key="imgIndex"
                          @click="
                            showLightbox(
                              row.index,
                              pruefpositionIndex,
                              imgIndex
                            )
                          "
                        >
                          <b-aspect
                            aspect="1:1"
                            class="d-flex align-items-center justify-content-center bg-light p-1 mb-2"
                          >
                            <b-img-lazy
                              fluid
                              class="detail-image"
                              :src="`${img}`"
                            />
                          </b-aspect>
                        </b-col>
                        <vue-easy-lightbox
                          escDisabled
                          moveDisabled
                          :visible="
                            pruefpositionenLightbox[row.index].pruefpositionen[
                              pruefpositionIndex
                            ].lightboxVisible
                          "
                          :imgs="
                            pruefpositionenLightbox[row.index].pruefpositionen[
                              pruefpositionIndex
                            ].imgUrls
                          "
                          :index="
                            pruefpositionenLightbox[row.index].pruefpositionen[
                              pruefpositionIndex
                            ].lightboxIndex
                          "
                          @hide="hideLightbox(row.index, pruefpositionIndex)"
                        />
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Mitarbeiter</h4>
            <b-row class="mx-0">
              <b-col
                md="4"
                class="mb-1"
                v-for="mitarbeiter in row.item.Mitarbeiter"
                :key="mitarbeiter.id"
              >
                <b-card class="details-card">
                  <b-media vertical-align="center" class="align-items-center">
                    <template #aside>
                      <b-avatar
                        size="100"
                        :src="
                          mitarbeiter.User.avatar
                            ? $dbBaseUrl +
                              mitarbeiter.User.avatar.formats.thumbnail.url
                            : ''
                        "
                        :text="`${mitarbeiter.Vorname.charAt()}${mitarbeiter.Nachname.charAt()}`"
                        :variant="`light-primary`"
                        :to="{
                          name: 'mitarbeiter-ansicht',
                          params: { id: mitarbeiter.id },
                        }"
                      />
                    </template>
                    <b-link
                      :to="{
                        name: 'mitarbeiter-ansicht',
                        params: { id: mitarbeiter.id },
                      }"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ `${mitarbeiter.Vorname} ${mitarbeiter.Nachname}` }}
                    </b-link>
                    <small class="text-muted">{{
                      mitarbeiter.User.email
                    }}</small>
                  </b-media>
                </b-card>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <div v-if="row.item.Reporting">
              <h4 class="mb-1">Reporting/Dokumentation</h4>
              <b-row class="mx-1">
                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Format_Email"
                >
                  <strong>Reports als Email: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Format_Email"
                    disabled
                  />
                </b-col>
                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Format_Fax">
                  <strong>Reports als Fax: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Format_Fax"
                    disabled
                  />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Format_Telefon"
                >
                  <strong>Reports per Telefon: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Format_Telefon"
                    disabled
                  />
                </b-col>

                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Tagesbericht"
                >
                  <strong>Tagesbericht: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Tagesbericht"
                    disabled
                  />
                </b-col>
                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Auswertung">
                  <strong>Auswertung: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Auswertung"
                    disabled
                  />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                  v-if="row.item.Reporting.Foto_Filmdokumentation"
                >
                  <strong>Foto- / Filmdokumentation: </strong>
                  <b-form-checkbox
                    class="d-inline-block"
                    :checked="row.item.Reporting.Foto_Filmdokumentation"
                    disabled
                  />
                </b-col>

                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Frequenz">
                  <strong>Reportingfrequenz: </strong>
                  {{ reportingFrequency(row.item.Reporting.Frequenz) }}
                </b-col>

                <b-col md="4" class="mb-1" v-if="row.item.Reporting.Empfaenger">
                  <strong>Reports senden an: </strong>
                  <a
                    :href="`mailto:${row.item.Reporting.Empfaenger}`"
                    v-if="row.item.Reporting.Empfaenger.includes('@')"
                    >{{ row.item.Reporting.Empfaenger }}</a
                  >
                  <p v-else>{{ row.item.Reporting.Empfaenger }}</p>
                </b-col>
              </b-row>
              <hr class="mt-0 mb-2" />
            </div>

            <h4 class="mb-1">Weitere Auftragsdaten</h4>
            <b-row class="mx-1">
              <b-col
                md="4"
                class="mb-1"
                v-if="row.item.Auftrag_Bei_Pruefanweisung"
              >
                <strong>Auftrag liegt bei Prüfanweisung: </strong>
                <b-form-checkbox
                  class="d-inline-block"
                  :checked="row.item.Auftrag_Bei_Pruefanweisung"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Auftrag_Bei_Muster">
                <strong>Auftrag liegt bei Muster: </strong>
                <b-form-checkbox
                  class="d-inline-block"
                  :checked="row.item.Auftrag_Bei_Muster"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Auftrag_Bei_Sonstige">
                <strong>Auftrag liegt bei: </strong
                >{{ row.item.Auftrag_Bei_Sonstige }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Details ausblenden
            </b-button>
          </b-card>
        </template>

        <!-- Column: Order Auftragsnummer -->
        <template #cell(Auftragsnummer)="data">
          <b-link
            :to="{
              name: 'auftrag-details',
              params: { id: data.item.id },
            }"
            >#{{ data.item.Auftragsnummer }}</b-link
          >
        </template>

        <!-- Column: Kunde -->
        <template #cell(Kunde)="data">
          {{ data.item.Kunde.Firma }}
        </template>

        <!-- Column: Ansprechpartner -->
        <template #cell(Ansprechpartner)="data">
          <div
            v-for="contactPerson in getContactPerson(data.item)"
            :key="contactPerson.id"
          >
            {{ contactPerson.Vorname }} {{ contactPerson.Nachname }}
          </div>
        </template>

        <!-- Column: Mitarbeiter -->
        <template #cell(Mitarbeiter)="data">
          <b-avatar-group size="40">
            <b-avatar
              v-for="employee in data.item.Mitarbeiter"
              :key="employee.id"
              v-b-tooltip.hover.bottom="
                `${employee.Vorname} ${employee.Nachname}`
              "
              :src="
                employee.User.avatar
                  ? $dbBaseUrl + employee.User.avatar.formats.thumbnail.url
                  : ''
              "
              :text="`${employee.Vorname.charAt()}${employee.Nachname.charAt()}`"
              :variant="`light-primary`"
              :to="{
                name: 'mitarbeiter-ansicht',
                params: { id: employee.id },
              }"
              class="pull-up"
            />
          </b-avatar-group>
        </template>

        <!-- Column: Startdatum -->
        <template #cell(Startdatum)="data">
          {{
            new Date(
              data.item.Auftragskonditionen.Startdatum
            ).toLocaleDateString('de-DE', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          }}
        </template>

        <!-- Column: Status -->
        <template #cell(Status)="data">
          <b-badge :variant="status[1][orderStatus(data.item.Status)]">
            {{ status[0][orderStatus(data.item.Status)] }}
          </b-badge>
        </template>
      </b-table>
    </div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Zeigt Auftrag {{ dataMeta.from }} bis {{ dataMeta.to }} von
            {{ dataMeta.of }} Aufträgen</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatarGroup,
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BTooltip,
  BMedia,
  BImgLazy,
  BAspect,
  BLink,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import AddOrderModal from '@/layouts/components/modals/AddOrderModal.vue';
import DeleteOrderModal from '@/layouts/components/modals/DeleteOrderModal.vue';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BAvatarGroup,
    BPagination,
    BFormInput,
    BTooltip,
    BMedia,
    BImgLazy,
    BAspect,
    BLink,
    vSelect,
    AddOrderModal,
    DeleteOrderModal,
    VBModal,
    VueEasyLightbox,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      totalRows: 0,
      tableKey: 1,
      filters: { searchQuery: '', statusFilter: '' },
      fields: [
        { key: 'Auftragsnummer', label: 'Auftragsnummer', sortable: true },
        { key: 'Kunde', label: 'Kunde', sortable: true },
        { key: 'Ansprechpartner', label: 'Ansprechpartner' },
        { key: 'Mitarbeiter', label: 'Mitarbeiter' },
        { key: 'Startdatum', label: 'Startdatum', sortable: true },
        { key: 'Status', label: 'Status', sortable: true },
        { key: 'Aktionen', label: 'Aktionen' },
      ],
      /* eslint-disable global-require */
      status: [
        {
          1: 'Warte auf Freigabe',
          2: 'Bestätigt',
          3: 'Bereit',
          4: 'Laufend',
          5: 'Beendet',
          6: 'Unterbrochen',
          7: 'Abgebrochen',
        },
        {
          1: 'secondary',
          2: 'info',
          3: 'success',
          4: 'dark',
          5: 'primary',
          6: 'warning',
          7: 'danger',
        },
      ],
      pruefpositionenLightbox: [],
    };
  },
  computed: {
    dataMeta() {
      const fromItems = this.perPage * (this.currentPage - 1) + 1;
      const toItems = this.perPage * (this.currentPage - 1) + this.perPage;

      return {
        from: fromItems,
        to:
          toItems < this.totalRows
            ? toItems
            : toItems - (toItems - this.totalRows),
        of: this.totalRows,
      };
    },
  },
  watch: {
    perPage(newValue, oldValue) {
      if (newValue !== oldValue) this.refetchTable();
    },
  },
  methods: {
    showLightbox(orderItemIndex, pruefpositionIndex, imgIndex) {
      this.pruefpositionenLightbox[orderItemIndex].pruefpositionen[
        pruefpositionIndex
      ].lightboxVisible = true;
      this.pruefpositionenLightbox[orderItemIndex].pruefpositionen[
        pruefpositionIndex
      ].lightboxIndex = imgIndex;
    },
    hideLightbox(orderItemIndex, pruefpositionIndex) {
      this.pruefpositionenLightbox[orderItemIndex].pruefpositionen[
        pruefpositionIndex
      ].lightboxVisible = false;
    },
    refetchTable() {
      this.tableKey += 1;
    },
    reportingFrequency(term) {
      if (term === 'Taeglich') return 'Täglich';
      if (term === 'Woechentlich') return 'Wöchentlich';
      if (term === 'Monatlich') return 'Monatlich oder bei Auftragsende';
    },
    orderStatus(order) {
      switch (true) {
        case order === 'Schwebend':
          return 1;
        case order === 'Bestaetigt':
          return 2;
        case order === 'Bereit':
          return 3;
        case order === 'Laufend':
          return 4;
        case order === 'Beendet':
          return 5;
        case order === 'Ruht':
          return 6;
        case order === 'Abgebrochen':
          return 7;
        default:
          return 1;
      }
    },
    getContactPerson(order) {
      const contactPerson = [];
      order.Kunde.Ansprechpartner.forEach(person => {
        if (person.id === order.Ansprechpartner_ID) {
          contactPerson.push(person);
        }
      });
      return contactPerson;
    },
    async orderItemsProvider(ctx) {
      switch (true) {
        case ctx.sortBy === 'Kunde':
          ctx.sortBy = 'Kunde.Firma';
          break;
        case ctx.sortBy === 'Startdatum':
          ctx.sortBy = 'Auftragskonditionen.Startdatum';
          break;
        case ctx.sortBy === '':
          ctx.sortBy = 'Auftragsnummer';
          break;
        default:
          break;
      }

      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: ctx.currentPage,
            pageSize: this.perPage,
          },
          sort: [`${ctx.sortBy}:${ctx.sortDesc ? 'desc' : 'asc'}`],
          filters: {
            $or: [
              {
                Auftragsnummer: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
            ],
            Status: {
              $eq:
                ctx.filter.statusFilter === null
                  ? undefined
                  : ctx.filter.statusFilter.value,
            },
          },
          fields: [
            'id',
            'Auftragsnummer',
            'Bestellnummer',
            'Status',
            'Ansprechpartner_ID',
            'Auftrag_Bei_Pruefanweisung',
            'Auftrag_Bei_Muster',
            'Auftrag_Bei_Sonstige',
          ],
          populate: {
            Mitarbeiter: {
              fields: ['Vorname', 'Nachname'],
              populate: {
                User: {
                  fields: ['email'],
                  populate: {
                    avatar: {
                      fields: ['formats'],
                    },
                  },
                },
                Kontakt: {
                  populate: '*',
                  fields: ['Mobil', 'Telefon'],
                },
              },
            },
            Kunde: {
              fields: ['Firma'],
              populate: {
                Ansprechpartner: {
                  fields: [
                    'Vorname',
                    'Nachname',
                    'Position',
                    'Abteilung',
                    'Email',
                    'Inaktiv',
                  ],
                },
                Kontakt: {
                  fields: ['Telefon', 'Fax'],
                },
                Anschrift: {
                  fields: '*',
                },
              },
            },
            Auftragskonditionen: {
              fields: '*',
            },
            Pruefpositionen: {
              fields: [
                'Stueckzahl',
                'Bauteilgruppe',
                'Bezeichnung',
                'Folgelieferung_Pruefen',
                'Status',
              ],
              populate: {
                Anhaenge: {
                  fields: ['url', 'formats'],
                },
              },
            },
            Abweichender_Auftragsort: {
              fields: '*',
            },
            Reporting: {
              fields: '*',
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let orderItems = [];

      await this.$http
        .get(`/auftraege?${query}`)
        .then(response => {
          orderItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
          orderItems.forEach((orderItem, orderItemIndex) => {
            this.pruefpositionenLightbox.push({
              pruefpositionen: [],
            });
            if (orderItem.Pruefpositionen.length > 0) {
              orderItem.Pruefpositionen.forEach(
                (pruefposition, pruefpositionIndex) => {
                  this.pruefpositionenLightbox[
                    orderItemIndex
                  ].pruefpositionen.push({
                    imgUrls: [],
                    imgThumbnailUrls: [],
                    lightboxIndex: 0,
                    lightboxVisible: false,
                  });
                  if (pruefposition.Anhaenge != null) {
                    pruefposition.Anhaenge.forEach(anhang => {
                      this.pruefpositionenLightbox[
                        orderItemIndex
                      ].pruefpositionen[pruefpositionIndex].imgUrls.push(
                        `${this.$dbBaseUrl}${anhang.url}`
                      );
                      this.pruefpositionenLightbox[
                        orderItemIndex
                      ].pruefpositionen[
                        pruefpositionIndex
                      ].imgThumbnailUrls.push(
                        `${this.$dbBaseUrl}${anhang.formats.thumbnail.url}`
                      );
                    });
                  }
                }
              );
            }
          });
        })
        .catch(err => console.log(err.response.data.error.message));
      return orderItems;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#status-selection {
  min-width: 15rem;
}
.per-page-selector {
  min-width: 6rem;
}

.google-map {
  height: 20rem;
  border-radius: 0.5rem;

  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.card.inactive {
  background-color: #f0f0f0;
}
.b-aspect {
  background-color: white;
  transition: all 0.25s ease-in;
  cursor: pointer;
}
.b-aspect:hover {
  transform: scale(1.05);
}
.b-aspect-content {
  display: flex;
  justify-content: center;
}
.detail-image {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
