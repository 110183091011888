<template>
  <div>
    <div v-if="$can('read', 'Admin')">
      <b-row class="match-height">
        <b-col lg="6">
          <employee-count />
        </b-col>
        <b-col lg="6">
          <order-pie-chart />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <order-table />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <employee-table />
        </b-col>
      </b-row>
    </div>
    <div v-if="$can('read', 'Mitarbeiter')">
      <b-row>
        <b-col>
          <employee-order-table />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <employee-order-table :finishedOrders="true" />
        </b-col>
      </b-row>
    </div>
    <div v-if="$can('read', 'Kunde')">
      <b-row>
        <b-col>
          <customer-order-table
            :unconfirmedOrders="true"
            @ordersUpdated="refetchTable"
            :customerId="customerId"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <customer-order-table ref="customerTable" :customerId="customerId" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import OrderTable from '@/layouts/components/tables/OrderTable.vue';
import EmployeeTable from '@/layouts/components/tables/EmployeeTable.vue';
import EmployeeOrderTable from '@/layouts/components/tables/EmployeeOrderTable.vue';
import CustomerOrderTable from '@/layouts/components/tables/CustomerOrderTable.vue';
import EmployeeCount from '@/layouts/components/widgets/EmployeeCount.vue';
import OrderPieChart from '@/layouts/components/widgets/OrderPieChart.vue';

export default {
  components: {
    BRow,
    BCol,
    OrderTable,
    OrderPieChart,
    CustomerOrderTable,
    EmployeeTable,
    EmployeeCount,
    EmployeeOrderTable,
  },
  computed: {
    customerId() {
      const customerId = JSON.parse(localStorage.getItem('userData')).kundenID;
      return customerId;
    },
  },
  methods: {
    refetchTable() {
      this.$refs.customerTable.refetchTable();
    },
  },
};
</script>

<style></style>
