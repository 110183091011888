<template>
  <b-modal
    :id="`modal-add-order-${uniqueName}`"
    centered
    title="Auftrag hinzufügen"
    ok-title="Hinzufügen"
    cancel-title="Abbrechen"
    cancel-variant="outline-secondary"
    @ok="addOrder"
    @cancel="initializeOrderData"
    @close="initializeOrderData"
  >
    <!-- Form -->
    <validation-observer ref="registerOrderForm" #default="{ invalid }">
      <b-form class="auth-register-form mt-2" @submit.prevent="register">
        <b-card-title class="mb-1">Grunddaten</b-card-title>

        <b-row>
          <!-- Auftragsnummer -->
          <b-col md="6">
            <b-form-group
              label="Auftragsnummer"
              label-for="register-auftragsnummer"
            >
              <validation-provider
                #default="{ errors }"
                name="Auftragsnummer"
                vid="auftragsnummer"
                rules="required|min:5"
              >
                <b-form-input
                  id="register-auftragsnummer"
                  v-model="orderData.Auftragsnummer"
                  name="register-auftragsnummer"
                  :state="errors.length > 0 ? false : null"
                  placeholder="12345"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="orderIdNotUnique" class="text-danger"
                  >Auftragsnummer bereits vergeben</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Bestellnummer -->
          <b-col md="6">
            <b-form-group
              label="Bestellnummer"
              label-for="register-bestellnummer"
            >
              <validation-provider
                #default="{ errors }"
                name="Bestellnummer"
                vid="bestellnummer"
                rules="min:5"
              >
                <b-form-input
                  id="register-bestellnummer"
                  v-model="orderData.Bestellnummer"
                  name="register-bestellnummer"
                  :state="errors.length > 0 ? false : null"
                  placeholder="12345"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Mitarbeiterbedarf -->
          <b-col md="6">
            <b-form-group
              label="Mitarbeiterbedarf"
              label-for="register-mitarbeiterbedarf"
            >
              <validation-provider
                #default="{ errors }"
                name="Mitarbeiterbedarf"
                vid="mitarbeiterbedarf"
                rules="between:1,20"
              >
                <b-form-input
                  id="register-mitarbeiterbedarf"
                  v-model="orderData.Auftragskonditionen.Mitarbeiterbedarf"
                  name="register-mitarbeiterbedarf"
                  :state="errors.length > 0 ? false : null"
                  placeholder="1"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Stundensatz -->
          <b-col md="6">
            <b-form-group
              label="Stundensatz"
              label-for="register-stundensatz"
            >
              <validation-provider
                #default="{ errors }"
                name="Stundensatz"
                vid="stundensatz"
                rules="between:1,200"
              >
                <b-input-group append="€">
                  <b-form-input
                    id="register-stundensatz"
                    v-model="orderData.Auftragskonditionen.Stundensatz"
                    name="register-stundensatz"
                    :state="errors.length > 0 ? false : null"
                    placeholder="30"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Anfahrtskosten -->
          <b-col md="6">
            <b-form-group
              label="Anfahrtskosten"
              label-for="register-anfahrtskosten"
            >
              <validation-provider
                #default="{ errors }"
                name="Anfahrtskosten"
                vid="anfahrtskosten"
                rules="between:1,200"
              >
                <b-input-group append="€">
                  <b-form-input
                    id="register-anfahrtskosten"
                    v-model="orderData.Auftragskonditionen.Anfahrtskosten"
                    name="register-anfahrtskosten"
                    :state="errors.length > 0 ? false : null"
                    placeholder="30"
                    type="number"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Auftragszeitraum -->
          <b-col md="12">
            <b-form-group
              label="Auftragszeitraum*"
              label-for="register-auftragszeitraum"
            >
              <validation-provider
                #default="{ errors }"
                name="Auftragszeitraum"
                vid="auftragszeitraum"
                rules="required"
              >
                <flat-pickr
                  id="register-auftragszeitraum"
                  v-model="orderDateRange"
                  :config="flatPickrConfig"
                  placeholder="Auftragszeitraum festlegen"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <b-card-title class="mb-1">Kunde</b-card-title>
        <b-row>
          <!-- Kunde -->
          <b-col md="12">
            <b-form-group label="Kunde*" label-for="register-kunde">
              <validation-provider
                #default="{ errors }"
                name="Kunde"
                vid="kunde"
                rules="required"
                :mode="
                  () => {
                    return { on: ['search:blur', 'input'] };
                  }
                "
              >
                <v-select
                  v-model="orderData.Kunde"
                  id="register-kunde"
                  :options="customers"
                  :reduce="customer => customer.id"
                  label="Firma"
                  placeholder="Firma auswählen"
                >
                  <template slot="option" slot-scope="option">
                    <b-media vertical-align="center" class="align-items-center">
                      <template #aside>
                        <b-avatar
                          class="kunde-avatar"
                          size="40"
                          :src="
                            option.User.avatar
                              ? $dbBaseUrl +
                                option.User.avatar.formats.thumbnail.url
                              : ''
                          "
                          :text="`${option.Firma.charAt()}`"
                          :variant="`light-secondary`"
                          :to="{
                            name: 'kunde-ansicht',
                            params: { id: option.id },
                          }"
                          rounded
                        />
                      </template>
                      {{ option.Firma }}
                    </b-media>
                  </template>
                  <template #no-options="{ search, searching, loading }">
                    {{ `Es wurden leider keine Kunden gefunden.` }}
                  </template>
                </v-select>
                <small v-if="!orderData.Kunde" class="text-danger">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Ansprechpartner -->
          <b-col md="12" v-if="orderData.Kunde">
            <b-form-group
              label="Ansprechpartner*"
              label-for="register-ansprechpartner"
            >
              <validation-provider
                #default="{ errors }"
                name="Ansprechpartner"
                vid="ansprechpartner"
                rules="required"
                :mode="
                  () => {
                    return { on: ['search:blur', 'input'] };
                  }
                "
              >
                <v-select
                  v-model="orderData.Ansprechpartner_ID"
                  id="register-ansprechpartner"
                  :options="ansprechpartner"
                  :reduce="contactPerson => contactPerson.id"
                  label="Nachname"
                  placeholder="Ansprechpartner auswählen"
                >
                  <template slot="selected-option" slot-scope="option"
                    >{{ option.Vorname }} {{ option.Nachname }}</template
                  >
                  <template slot="option" slot-scope="option"
                    >{{ option.Vorname }} {{ option.Nachname }}</template
                  >
                  <template #no-options="{ search, searching, loading }">
                    {{ `Es wurden leider keine Ansprechpartner gefunden.` }}
                  </template>
                </v-select>
                <small
                  v-if="!orderData.Ansprechpartner_ID"
                  class="text-danger"
                  >{{ errors[0] }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Abweichender Auftragsort -->
          <b-col md="12" class="mb-1">
            <b-form-checkbox
              v-model="abweichenderAuftragsort"
              name="check-button"
              switch
              inline
            >
              Auftragsort weicht von Firmensitz ab?
            </b-form-checkbox>
          </b-col>

          <!-- Abweichender Auftragsort - Straße -->
          <b-col md="6" v-if="abweichenderAuftragsort">
            <b-form-group
              label="Straße"
              label-for="register-auftragsort-strasse"
            >
              <b-form-input
                id="register-auftragsort-strasse"
                v-model="orderData.Abweichender_Auftragsort.Strasse"
                name="register-auftragsort-strasse"
                placeholder="Musterstraße"
              />
            </b-form-group>
          </b-col>

          <!-- Abweichender Auftragsort - Hausnummer -->
          <b-col md="6" v-if="abweichenderAuftragsort">
            <b-form-group
              label="Hausnummer"
              label-for="register-auftragsort-hausnummer"
            >
              <b-form-input
                id="register-auftragsort-hausnummer"
                v-model="orderData.Abweichender_Auftragsort.Hausnummer"
                name="register-auftragsort-hausnummer"
                placeholder="1"
              />
            </b-form-group>
          </b-col>

          <!-- Abweichender Auftragsort - Adresszusatz -->
          <b-col md="6" v-if="abweichenderAuftragsort">
            <b-form-group
              label="Adresszusatz"
              label-for="register-auftragsort-adresszusatz"
            >
              <b-form-input
                id="register-auftragsort-adresszusatz"
                v-model="orderData.Abweichender_Auftragsort.Zusatz"
                name="register-auftragsort-adresszusatz"
                placeholder="1"
              />
            </b-form-group>
          </b-col>

          <!-- Abweichender Auftragsort - PLZ -->
          <b-col md="6" v-if="abweichenderAuftragsort">
            <b-form-group label="PLZ" label-for="register-auftragsort-plz">
              <b-form-input
                id="register-auftragsort-plz"
                v-model="orderData.Abweichender_Auftragsort.PLZ"
                name="register-auftragsort-plz"
                placeholder="12345"
              />
            </b-form-group>
          </b-col>

          <!-- Abweichender Auftragsort - Stadt -->
          <b-col md="6" v-if="abweichenderAuftragsort">
            <b-form-group label="Stadt" label-for="register-auftragsort-stadt">
              <b-form-input
                id="register-auftragsort-stadt"
                v-model="orderData.Abweichender_Auftragsort.Stadt"
                name="register-auftragsort-stadt"
                placeholder="Musterstadt"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <b-card-title class="mb-1">Prüfpositionen</b-card-title>
        <b-row>
          <b-col
            v-for="(controlPosition, index) in controlPositions"
            :key="index"
            md="12"
          >
            <b-card class="position-relative">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-delete-control-position position-absolute"
                @click="deleteControlPosition(index)"
              >
                <feather-icon icon="TrashIcon" class="mr-25" />
              </b-button>
              <b-card-title title-tag="h5" class="mb-1"
                >Prüfposition {{ index + 1 }}</b-card-title
              >
              <b-row class="mx-0">
                <!-- Prüfposition - Art der Tätigkeit -->
                <b-col md="12">
                  <b-form-group label="Art der Tätigkeit">
                    <b-form-checkbox
                      class="mb-50"
                      :id="`register-taetigkeit-kontrolle-pruefen-${index}`"
                      v-model="controlPosition.Taetigkeit.Kontrolle_Pruefen"
                      :name="`register-taetigkeit-kontrolle-pruefen-${index}`"
                      >Kontrolle/Prüfen</b-form-checkbox
                    >
                    <b-form-checkbox
                      class="mb-50"
                      :id="`register-taetigkeit-nacharbeit-${index}`"
                      v-model="controlPosition.Taetigkeit.Nacharbeit"
                      :name="`register-taetigkeit-nacharbeit-${index}`"
                      >Nacharbeit</b-form-checkbox
                    >
                    <b-form-checkbox
                      class="mb-50"
                      :id="`register-taetigkeit-stichprobe-${index}`"
                      v-model="controlPosition.Taetigkeit.Stichprobe"
                      :name="`register-taetigkeit-stichprobe-${index}`"
                      >Stichprobe</b-form-checkbox
                    >
                    <b-form-checkbox
                      class="mb-50"
                      :id="`register-taetigkeit-umpacken-komplettieren-${index}`"
                      v-model="
                        controlPosition.Taetigkeit.Umpacken_Komplettieren
                      "
                      :name="`register-taetigkeit-umpacken-komplettieren-${index}`"
                      >Umpacken/Komplettieren</b-form-checkbox
                    >
                    <b-form-checkbox
                      class="mb-50"
                      :id="`register-taetigkeit-kontrolle-mit-nacharbeit-${index}`"
                      v-model="
                        controlPosition.Taetigkeit.Kontrolle_mit_Nacharbeit
                      "
                      :name="`register-taetigkeit-kontrolle-mit-nacharbeit-${index}`"
                      >Kontrolle mit Nacharbeit</b-form-checkbox
                    >
                    <b-form-input
                      :id="`register-taetigkeit-sonstiges-${index}`"
                      v-model="controlPosition.Taetigkeit.Sonstiges"
                      :name="`register-taetigkeit-sonstiges-${index}`"
                      placeholder="Sonstiges"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Bauteilgruppe -->
                <b-col md="6">
                  <b-form-group
                    label="Bauteilgruppe"
                    :label-for="`register-bauteilgruppe-${index}`"
                  >
                    <b-form-input
                      :id="`register-bauteilgruppe-${index}`"
                      v-model="controlPosition.Bauteilgruppe"
                      :name="`register-bauteilgruppe-${index}`"
                      placeholder="Bauteilgruppe"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Bezeichnung -->
                <b-col md="6">
                  <b-form-group
                    label="Bezeichnung"
                    :label-for="`register-bezeichnung-${index}`"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Bezeichnung"
                      vid="bezeichnung"
                      rules="required|min:5"
                    >
                      <b-form-input
                        :id="`register-bezeichnung-${index}`"
                        v-model="controlPosition.Bezeichnung"
                        :name="`register-bezeichnung-${index}`"
                        placeholder="Bezeichnung"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Artikelnummer -->
                <b-col md="6">
                  <b-form-group
                    label="Artikelnummer"
                    :label-for="`register-artikelnummer-${index}`"
                  >
                    <b-form-input
                      :id="`register-artikelnummer-${index}`"
                      v-model="controlPosition.Artikelnummer"
                      :name="`register-artikelnummer-${index}`"
                      placeholder="Artikelnummer"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Chargennummer -->
                <b-col md="6">
                  <b-form-group
                    label="Chargennummer"
                    :label-for="`register-chargennummer-${index}`"
                  >
                    <b-form-input
                      :id="`register-chargennummer-${index}`"
                      v-model="controlPosition.Chargennummer"
                      :name="`register-chargennummer-${index}`"
                      placeholder="Chargennummer"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Reklamationsgrund -->
                <b-col md="12">
                  <b-form-group
                    label="Reklamationsgrund"
                    :label-for="`register-reklamationsgrund-${index}`"
                  >
                    <b-form-input
                      :id="`register-reklamationsgrund-${index}`"
                      v-model="controlPosition.Reklamationsgrund"
                      :name="`register-reklamationsgrund-${index}`"
                      placeholder="Reklamationsgrund"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Auszuführende Tätigkeit -->
                <b-col md="12">
                  <b-form-group
                    label="Auszuführende Tätigkeit"
                    :label-for="`register-auszufuehrende-taetigkeit-${index}`"
                  >
                    <b-form-textarea
                      :id="`register-auszufuehrende-taetigkeit-${index}`"
                      v-model="controlPosition.Auszufuehrende_Taetigkeit"
                      :name="`register-auszufuehrende-taetigkeit-${index}`"
                      placeholder="Auszuführende Tätigkeit"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Stückzahl -->
                <b-col md="6">
                  <b-form-group
                    label="Stückzahl"
                    :label-for="`register-stueckzahl-${index}`"
                  >
                    <b-form-input
                      :id="`register-stueckzahl-${index}`"
                      v-model="controlPosition.Stueckzahl"
                      :name="`register-stueckzahl-${index}`"
                      placeholder="Stückzahl"
                    />
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Folgelieferung prüfen -->
                <b-col md="6">
                  <b-form-group
                    label="Folgelieferung prüfen"
                    :label-for="`register-folgelieferung-pruefen-${index}`"
                  >
                    <b-form-checkbox
                      v-model="controlPosition.Folgelieferung_Pruefen"
                      :id="`register-folgelieferung-pruefen-${index}`"
                      :name="`register-folgelieferung-pruefen-${index}`"
                      switch
                      inline
                    >
                      {{
                        controlPosition.Folgelieferung_Pruefen ? 'Ja' : 'Nein'
                      }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Prüfposition - Detailbilder hochladen -->
                <b-col md="12" v-if="newDetailImages[index]">
                  <b-form-group
                    label="Detailbilder hochladen"
                    :label-for="`register-detailbilder-hochladen-${index}`"
                  >
                  <b-form-file
                      :id="`register-detailbilder-hochladen-${index + 1}`"
                      v-model="newDetailImages[index].files"
                      accept="image/*"
                      placeholder="Bilder auswählen ..."
                      drop-placeholder="Bilder hierher ziehen ..."
                      multiple
                      @input="makeNewDetailImageUrls(index)"
                    />
                    <b-row class="mt-1">
                      <b-col
                        cols="4"
                        v-for="(img, imgIndex) in newDetailImages[index].urls"
                        :key="imgIndex"
                      >
                        <b-aspect
                          aspect="1:1"
                          class="d-flex align-items-center bg-light p-1 position-relative"
                        >
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-delete-detail-image position-absolute"
                            @click.stop="deleteNewDetailImage(index, imgIndex)"
                          >
                            <feather-icon icon="TrashIcon" class="mr-25" />
                          </b-button>
                          <b-img-lazy
                            fluid
                            class="detail-image"
                            :src="`${img}`"
                          />
                        </b-aspect>
                      </b-col>
                      <!-- <vue-easy-lightbox
                        escDisabled
                        moveDisabled
                        :visible="
                          pruefpositionenLightbox[index].lightboxVisible
                        "
                        :imgs="pruefpositionenLightbox[index].imgUrls"
                        :index="pruefpositionenLightbox[index].lightboxIndex"
                        @hide="hideLightbox(index)"
                      /> -->
                    </b-row>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="addControlPosition"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Prüfposition hinzufügen</span>
            </b-button>
          </b-col>
        </b-row>
        <hr />

        <!-- Mitarbeiter auswählen -->
        <b-row>
          <b-col md="12" class="mb-1">
            <b-form-checkbox
              v-model="mitarbeiterAuswaehlen"
              name="check-button"
              switch
              inline
            >
              Sollen bereits Mitarbeiter zugewiesen werden?
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-card-title class="mb-1" v-if="mitarbeiterAuswaehlen"
          >Mitarbeiter</b-card-title
        >
        <b-row v-if="mitarbeiterAuswaehlen">
          <!-- Mitarbeiter -->
          <b-col md="12">
            <b-form-group label="Mitarbeiter" label-for="register-mitarbeiter">
              <v-select
                id="register-mitarbeiter"
                v-model="orderData.Mitarbeiter"
                multiple
                :options="employees"
                :reduce="employee => employee.id"
                label="Nachname"
                placeholder="Mitarbeiter auswählen"
                
              >
                <template slot="selected-option" slot-scope="option"
                  >{{ option.Vorname }} {{ option.Nachname }}</template
                >
                <template slot="option" slot-scope="option">
                  <b-row>
                    <b-col cols="7">
                      <b-media
                        vertical-align="center"
                        class="align-items-center"
                      >
                        <template #aside>
                          <b-avatar
                            class="mitarbeiter-avatar"
                            size="40"
                            :src="
                              option.User.avatar
                                ? $dbBaseUrl +
                                  option.User.avatar.formats.thumbnail.url
                                : ''
                            "
                            :text="`${option.Vorname.charAt()}${option.Nachname.charAt()}`"
                            :variant="`light-secondary`"
                            :to="{
                              name: 'mitarbeiter-ansicht',
                              params: { id: option.id },
                            }"
                            rounded
                          />
                        </template>
                        <p class="mb-0">
                          {{ `${option.Vorname} ${option.Nachname}` }}
                        </p>
                        <div>
                          <b-badge
                            class="mr-50"
                            :variant="rating[1][option.Bewertung]"
                          >
                            {{ option.Bewertung }}
                          </b-badge>
                          <b-badge
                            :variant="
                              status[1][
                                option.Verfuegbar === 'Im_Auftrag'
                                  ? 1
                                  : option.Verfuegbar === 'Verfuegbar'
                                  ? 2
                                  : option.Verfuegbar === 'Krank'
                                  ? 3
                                  : 4
                              ]
                            "
                          >
                            {{
                              status[0][
                                option.Verfuegbar === 'Im_Auftrag'
                                  ? 1
                                  : option.Verfuegbar === 'Verfuegbar'
                                  ? 2
                                  : option.Verfuegbar === 'Krank'
                                  ? 3
                                  : 4
                              ]
                            }}
                          </b-badge>
                        </div>
                      </b-media>
                    </b-col>
                    <b-col md="5" class="employee-skills">
                      <p
                        class="mb-0"
                        v-if="option.Kenntnisse.Qualitaetssicherung"
                      >
                        Qualitätssicherung
                      </p>
                      <p class="mb-0" v-if="option.Kenntnisse.Messmittel">
                        Messmittel
                      </p>
                    </b-col>
                  </b-row>
                </template>
                <template #no-options="{ search, searching, loading }">
                  {{ `Es wurden leider keine Mitarbeiter gefunden.` }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <hr v-if="mitarbeiterAuswaehlen" />

        <b-card-title class="mb-1">Weitere Auftragsdaten</b-card-title>
        <b-row>
          <!-- Auftrag liegt bei ... -->
          <b-col md="12">
            <b-form-group
              label="Auftrag liegt bei ..."
              label-for="register-auftrag-liegt-bei"
            >
              <b-row class="d-flex" id="register-auftrag-liegt-bei">
                <b-col md="6">
                  <b-form-checkbox
                    id="register-auftrag-bei-pruefanweisung"
                    class="w-50"
                    v-model="orderData.Auftrag_Bei_Pruefanweisung"
                    name="register-auftrag-bei-pruefanweisung"
                    >Prüfanweisung</b-form-checkbox
                  >
                </b-col>
                <b-col md="6">
                  <b-form-checkbox
                    id="register-auftrag-bei-muster"
                    class="w-50"
                    v-model="orderData.Auftrag_Bei_Muster"
                    name="register-auftrag-bei-muster"
                    >Muster</b-form-checkbox
                  >
                </b-col>
                <b-col md="12" class="mt-50">
                  <b-form-input
                    id="register-auftrag-bei-sonstige"
                    v-model="orderData.Auftrag_Bei_Sonstige"
                    name="register-auftrag-bei-sonstige"
                    placeholder="Sonstige"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />

        <!-- Reporting hinzufügen -->
        <b-row>
          <b-col md="12" class="mb-1">
            <b-form-checkbox
              v-model="reporting"
              name="check-button"
              switch
              inline
            >
              Ist ein Reporting oder eine Dokumentation gewünscht?
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-card-title class="mb-1" v-if="reporting"
          >Reporting/Dokumentation</b-card-title
        >
        <b-row v-if="reporting">
          <!-- Reporting - Format -->
          <b-col md="6">
            <b-form-group label="Format" label-for="register-reporting-format">
              <b-row class="d-flex" id="register-reporting-format">
                <b-col md="12">
                  <b-form-checkbox
                    id="register-reporting-format-email"
                    class="w-50"
                    v-model="orderData.Reporting.Format_Email"
                    name="register-reporting-format-email"
                    >Email</b-form-checkbox
                  >
                </b-col>
                <b-col md="12" class="mt-50">
                  <b-form-checkbox
                    id="register-reporting-format-fax"
                    class="w-50"
                    v-model="orderData.Reporting.Format_Fax"
                    name="register-reporting-format-fax"
                    >Fax</b-form-checkbox
                  >
                </b-col>
                <b-col md="12" class="mt-50">
                  <b-form-checkbox
                    id="register-reporting-format-telefon"
                    class="w-50"
                    v-model="orderData.Reporting.Format_Telefon"
                    name="register-reporting-format-telefon"
                    >Telefon</b-form-checkbox
                  >
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <!-- Reporting - Art -->
          <b-col md="6">
            <b-form-group label="Art" label-for="register-reporting-art">
              <b-row class="d-flex" id="register-reporting-art">
                <b-col md="12">
                  <b-form-checkbox
                    id="register-reporting-art-tagesbericht"
                    class="w-50"
                    v-model="orderData.Reporting.Tagesbericht"
                    name="register-reporting-art-tagesbericht"
                    >Tagesbericht</b-form-checkbox
                  >
                </b-col>
                <b-col md="12" class="mt-50">
                  <b-form-checkbox
                    id="register-reporting-art-auswertung"
                    class="w-50"
                    v-model="orderData.Reporting.Auswertung"
                    name="register-reporting-art-auswertung"
                    >Auswertung</b-form-checkbox
                  >
                </b-col>
                <b-col md="12" class="mt-50">
                  <b-form-checkbox
                    id="register-reporting-art-foto-filmdokumentation"
                    class="w-50"
                    v-model="orderData.Reporting.Foto_Filmdokumentation"
                    name="register-reporting-art-foto-filmdokumentation"
                    >Foto-/Filmdokumentation</b-form-checkbox
                  >
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <!-- Reporting - Frequenz -->
          <b-col md="12">
            <b-form-group
              label="Frequenz"
              label-for="register-reporting-frequenz"
            >
              <v-select
                id="register-reporting-frequenz"
                v-model="orderData.Reporting.Frequenz"
                :reduce="frequenz => frequenz.value"
                :options="[
                  { label: 'Täglich', value: 'Taeglich' },
                  { label: 'Wöchentlich', value: 'Woechentlich' },
                  {
                    label: 'Monatlich, bzw. am Auftragsende',
                    value: 'Monatlich',
                  },
                ]"
                placeholder="Berichtfrequenz festlegen"
              />
            </b-form-group>
          </b-col>

          <!-- Reporting - Epfänger -->
          <b-col md="12">
            <b-form-group
              label="Empfänger"
              label-for="register-reporting-empfaenger"
            >
              <b-form-input
                id="register-reporting-empfaenger"
                v-model="orderData.Reporting.Empfaenger"
                name="register-reporting-empfaenger"
                placeholder="Email-Adresse, Telefonnummer, Fax-Nummer"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BButton,
  BBadge,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
  BFormRadioGroup,
  BInputGroup,
  BMedia,
  BAvatar,
  BImgLazy,
  BAspect,
  BFormFile,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import { German } from 'flatpickr/dist/l10n/de';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

localize('de', de);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BBadge,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormDatepicker,
    BFormTimepicker,
    BFormRadioGroup,
    BImgLazy,
    BAspect,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BMedia,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    uniqueName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orderDateRange: '',
      orderIdNotUnique: false,
      flatPickrConfig: {
        mode: 'range',
        enableTime: true,
        defaultHour: 7,
        dateFormat: 'Z',
        altInput: true,
        altFormat: 'd.m.Y, H:i \\U\\h\\r',
        allowInput: true,
        time_24hr: true,
        locale: German,
      },
      orderData: {},
      customers: [],
      employees: [],
      controlPositions: [],
      reporting: false,
      abweichenderAuftragsort: false,
      mitarbeiterAuswaehlen: false,
      status: [
        {
          1: 'Im Auftrag',
          2: 'Verfügbar',
          3: 'Krank',
          4: '-',
        },
        {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'secondary',
        },
      ],
      rating: [
        {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
        },
        {
          1: 'success',
          2: 'primary',
          3: 'info',
          4: 'secondary',
          5: 'warning',
          6: 'danger',
        },
      ],
      // validation
      required,
      email,
      newDetailImages: [],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    ansprechpartner() {
      if (this.orderData.Kunde !== null) {
        return this.customers.find(
          customer => customer.id === this.orderData.Kunde
        ).Ansprechpartner;
      }
      return [];
    },
  },
  watch: {
    'orderData.Auftragsnummer': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Auftragsnummer: {
                $eq: this.orderData.Auftragsnummer,
              },
            },
            fields: ['id', 'Auftragsnummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/auftraege?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.orderIdNotUnique = true;
            } else {
              this.orderIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
  },
  async created() {
    this.initializeOrderData();
    this.getCustomers();
    this.getEmployees();
  },
  methods: {
    makeNewDetailImageUrls(pruefpositionIndex) {
      if (this.newDetailImages[pruefpositionIndex].files.length === 0) {
        this.newDetailImages[pruefpositionIndex].urls = [];
      } else {
        this.newDetailImages[pruefpositionIndex].files.forEach(image => {
          base64Encode(image).then(value => {
            this.newDetailImages[pruefpositionIndex].urls.push(value);
          });
        });
      }
    },
    deleteNewDetailImage(pruefpositionIndex, imgIndex) {
      this.newDetailImages[pruefpositionIndex].files.splice(imgIndex, 1);
      this.newDetailImages[pruefpositionIndex].urls.splice(imgIndex, 1);
    },
    async getEmployees() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: 1,
            pageSize: 100,
          },
          fields: ['Vorname', 'Nachname', 'Verfuegbar', 'Bewertung'],
          populate: {
            User: {
              fields: ['id', 'email'],
              populate: { avatar: { fields: ['formats'] } },
            },
            Kenntnisse: { fields: '*' },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let employeeItems = [];

      await this.$http
        .get(`/mitarbeiters?${query}`)
        .then(response => {
          employeeItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.employees = employeeItems;
    },
    async getCustomers() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: 1,
            pageSize: 100,
          },
          fields: ['Firma'],
          populate: {
            Ansprechpartner: { fields: ['Vorname', 'Nachname'] },
            User: {
              fields: ['id'],
              populate: { avatar: { fields: ['formats'] } },
            },
          },
        },
        {
          encodeValuesOnly: true,
        }
      );

      let customerItems = [];

      await this.$http
        .get(`/kunden?${query}`)
        .then(response => {
          customerItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.customers = customerItems;
    },
    async addOrder(modalEvent) {
      modalEvent.preventDefault();

      this.$refs.registerOrderForm.validate().then(async isValid => {
        if (!isValid || this.orderIdNotUnique) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        // Register Control Positions
        if (this.controlPositions.length !== 0 && !this.orderIdNotUnique) {
          await Promise.all(
            this.controlPositions.map(async (controlPosition, index) => {
              await this.$http
                .post(`/pruefpositionen`, { data: controlPosition })
                .then(async response => {
                  this.orderData.Pruefpositionen.push(response.data.data.id);

                  // Register Detail Images
                  if (this.newDetailImages[index].files.length > 0) {
                    this.newDetailImages[index].files.forEach(async file => {
                      const formData = new FormData();
                      formData.append('field', 'Anhaenge');
                      formData.append('ref', 'api::pruefposition.pruefposition');
                      formData.append(
                        'refId',
                        response.data.data.id
                      );
                      formData.append('files', file, file.name);

                      await this.$http
                        .post('/upload', formData)
                        .catch(err => console.log(err.response.data.error.message));
                    });
                  }
                })
                .catch(err => {
                  console.log(err.response.data.error.message);
                });
            })
          );
        } else {
          delete this.orderData['Pruefpositionen'];
        }

        if (Object.keys(this.orderData.Abweichender_Auftragsort).length === 0) {
          delete this.orderData['Abweichender_Auftragsort'];
        }

        if (Object.keys(this.orderData.Reporting).length === 0) {
          delete this.orderData['Reporting'];
        }

        if (this.orderData.Mitarbeiter.length === 0) {
          delete this.orderData['Mitarbeiter'];
        }

        // Set Auftragszeitraum
        [
          this.orderData.Auftragskonditionen.Startdatum,
          this.orderData.Auftragskonditionen.Enddatum,
        ] = this.orderDateRange.split(' bis ');

        // Set Auftragszeit
        const date = new Date(this.orderData.Auftragskonditionen.Startdatum);

        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

        this.orderData.Auftragskonditionen.Startzeit = `${hours}:${minutes}:${seconds}.${milliseconds}`;

        // Register Order
        await this.$http
          .post(`/auftraege`, { data: this.orderData })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Auftrag angelegt`,
                icon: 'SaveIcon',
                variant: 'success',
                text: `Der Auftrag #${this.orderData.Auftragsnummer} wurde erfolgreich gespeichert.`,
              },
            });

            // Clear Order Data
            this.initializeOrderData();

            // Emit Event to Parent Component
            this.$emit('newOrderAdded');

            // Close Modal
            this.$nextTick(() => {
              this.$bvModal.hide(`modal-add-order-${this.uniqueName}`);
            });
          })
          .catch(err => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Anlegen des Auftrags fehlgeschlagen!`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `Der Auftrag konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
              },
            });
            console.log(err.response.data.error);
          });
      });
    },

    // Clear Image
    clearImage() {
      this.image = null;
    },

    // Add Control Position
    addControlPosition() {
      this.controlPositions.push({
        Bauteilgruppe: null,
        Bezeichnung: null,
        Reklamationsgrund: null,
        Auszufuehrende_Taetigkeit: null,
        Stueckzahl: null,
        Folgelieferung_Pruefen: false,
        Chargennummer: null,
        Artikelnummer: null,
        Taetigkeit: {
          Kontrolle_Pruefen: false,
          Nacharbeit: false,
          Stichprobe: false,
          Umpacken_Komplettieren: false,
          Kontrolle_mit_Nacharbeit: false,
          Sonstiges: null,
        },
      });
      this.newDetailImages.push({files: [], urls:[]})
    },

    // Delete Control Position
    deleteControlPosition(index) {
      this.controlPositions.splice(index, 1);
      this.newDetailImages.splice(index,1);
    },

    // Clear Customer Data
    initializeOrderData() {
      this.orderDateRange = '';
      this.orderData = {
        Auftragsnummer: null,
        Bestellnummer: null,
        Auftragskonditionen: {
          Startdatum: null,
          Enddatum: null,
          Anfahrtskosten: null,
          Mitarbeiterbedarf: null,
          Stundensatz: null,
        },
        Ansprechpartner_ID: null,
        Abweichender_Auftragsort: {},
        Auftrag_Bei_Pruefanweisung: false,
        Auftrag_Bei_Muster: false,
        Auftrag_Bei_Sonstige: '',
        Reporting: {},
        Pruefpositionen: [],
        Kunde: null,
        Mitarbeiter: [],
      };
      this.clearImage();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.avatar-preview {
  aspect-ratio: 1;
  object-fit: cover;
}
#register-kunde .b-avatar-img img {
  object-fit: contain !important;
}
#register-folgelieferung-pruefen + label {
  white-space: nowrap;
}
.employee-skills {
  border-left: 1px solid #206898;
}
.btn-delete-control-position {
  top: -0.5rem;
  right: -0.5rem;
}
.b-aspect {
  background-color: white;
  transition: all 0.25s ease-in;
}
.b-aspect:hover {
  transform: scale(1.05);
}
.detail-image {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
.btn-delete-detail-image {
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.5rem;
}
</style>
