<template>
  <b-card title="Mitarbeiter" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="UsersIcon" />
        <b-card-title class="ml-25">Mitarbeiter</b-card-title>
      </div>
    </b-card-header>
    <div class="mx-2 mb-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="mb-0">Zeige</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label class="mb-0">Mitarbeiter</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <label class="mb-0 mr-50 text-nowrap" for="status-selection"
              >Status filtern:</label
            >
            <v-select
              id="status-selection"
              v-model="filters.statusFilter"
              label="title"
              :options="[
                { title: 'Verfügbar', value: 'Verfuegbar' },
                { title: 'Im Auftrag', value: 'Im_Auftrag' },
                { title: 'Krank', value: 'Krank' },
              ]"
            />
            <b-form-input
              v-model="filters.searchQuery"
              class="d-inline-block mx-1"
              placeholder="Suchen..."
            />
            <b-button variant="primary" v-b-modal.modal-add-employee>
              <span class="text-nowrap">Mitarbeiter hinzufügen</span>
            </b-button>
            <add-employee-modal @newUserAdded="refetchTable" />
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-table
        :key="tableKey"
        :items="employeeItemsProvider"
        :fields="fields"
        :per-page="0"
        :current-page="currentPage"
        :filter="filters"
        sort-by="Nachname"
        striped
        responsive
        class="mb-0"
      >
        <!-- Column: Actions -->
        <template #cell(Aktionen)="row">
          <div class="text-nowrap">
            <feather-icon
              :id="`employee-row-${row.item.id}-preview-icon`"
              icon="EyeIcon"
              size="20"
              class="mr-50 cursor-pointer text-primary"
              @click="row.toggleDetails"
            />
            <b-tooltip
              title="Infos einblenden"
              class="cursor-pointer"
              :target="`employee-row-${row.item.id}-preview-icon`"
            />
            <feather-icon
              :id="`employee-row-${row.item.id}-details-icon`"
              icon="FileTextIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'mitarbeiter-ansicht',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Details"
              class="cursor-pointer"
              :target="`employee-row-${row.item.id}-details-icon`"
            />
            <feather-icon
              :id="`employee-row-${row.item.id}-edit-icon`"
              icon="EditIcon"
              size="20"
              class="mx-50 cursor-pointer text-primary"
              @click="
                $router.push({
                  name: 'mitarbeiter-bearbeiten',
                  params: { id: row.item.id },
                })
              "
            />
            <b-tooltip
              title="Bearbeiten"
              class="cursor-pointer"
              :target="`employee-row-${row.item.id}-edit-icon`"
            />
            <feather-icon
              :id="`employee-row-${row.item.id}-delete-icon`"
              icon="TrashIcon"
              size="20"
              class="mx-50 cursor-pointer text-danger"
              v-b-modal:[`modal-delete-employee-${row.item.id}`]
            />
            <b-tooltip
              title="Löschen"
              class="cursor-pointer"
              :target="`employee-row-${row.item.id}-delete-icon`"
            />
            <delete-employee-modal
              :userData="row.item"
              @userDeleted="refetchTable"
            />
          </div>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card class="details-card">
            <h4 class="mb-1">Allgemein</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Name: </strong
                >{{ `${row.item.Vorname} ${row.item.Nachname}` }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Email: </strong
                ><a :href="`mailto:${row.item.User.email}`" target="_blank">{{
                  row.item.User.email
                }}</a>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Geburtstag: </strong
                >{{
                  new Date(row.item.Geburtstag).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Telefon: </strong
                ><a :href="`tel:${row.item.Kontakt.Telefon}`" target="_blank">{{
                  row.item.Kontakt.Telefon
                }}</a>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Mobil: </strong
                ><a :href="`tel:${row.item.Kontakt.Mobil}`" target="_blank">{{
                  row.item.Kontakt.Mobil
                }}</a>
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Anschrift</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Straße: </strong
                >{{
                  `${row.item.Anschrift.Strasse} ${row.item.Anschrift.Hausnummer}`
                }}
              </b-col>
              <b-col md="4" class="mb-1" v-if="row.item.Anschrift.Zusatz">
                <strong>Adresszusatz: </strong
                >{{ `${row.item.Anschrift.Zusatz}` }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>PLZ: </strong>{{ row.item.Anschrift.PLZ }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Stadt: </strong>{{ row.item.Anschrift.Stadt }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Mobilität: </strong>{{ row.item.Mobilitaet }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Anstellung</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1">
                <strong>Anstellungsart: </strong>{{ row.item.Anstellungsart }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Stundensatz: </strong>{{ row.item.Stundensatz }} €
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong>Arbeitserlaubnis: </strong>
                <b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Arbeitserlaubnis"
                  disabled
                />
              </b-col>
              <b-col
                md="4"
                class="mb-1"
                v-if="
                  row.item.Gueltigkeit_Arbeitserlaubnis &&
                  row.item.Arbeitserlaubnis
                "
              >
                <strong>Arbeitserlaubnis bis: </strong
                >{{
                  new Date(
                    row.item.Gueltigkeit_Arbeitserlaubnis
                  ).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })
                }}
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 class="mb-1">Verfügbarkeit</h4>
            <b-row class="mx-1">
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Frühschicht: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Fruehschicht"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Spätschicht: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Spaetschicht"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Nachtschicht: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Nachtschicht"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Montag: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Montag"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Dienstag: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Dienstag"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Mittwoch: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Mittwoch"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Donnerstag: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Donnerstag"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Freitag: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Freitag"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Samstag: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Samstag"
                  disabled
                />
              </b-col>
              <b-col md="4" class="mb-1 d-flex">
                <strong class="w-25">Sonntag: </strong
                ><b-form-checkbox
                  class="ml-1"
                  :checked="row.item.Verfuegbarkeit.Sonntag"
                  disabled
                />
              </b-col>
            </b-row>
            <hr class="mt-0 mb-2" />

            <h4 v-if="row.item.Sonstiges" class="mb-1">Sonstiges</h4>
            <b-row v-if="row.item.Sonstiges" class="mx-1">
              <b-col class="mb-1">
                {{ row.item.Sonstiges }}
              </b-col>
            </b-row>
            <hr v-if="row.item.Sonstiges" class="mt-0 mb-2" />

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Details ausblenden
            </b-button>
          </b-card>
        </template>

        <!-- Column: Mitarbeiternummer -->
        <template #cell(Mitarbeiternummer)="data">
          <b-link
            :to="{
              name: 'mitarbeiter-ansicht',
              params: { id: data.item.id },
            }"
            >#{{ data.item.Mitarbeiternummer }}</b-link
          >
        </template>

        <!-- Column: User -->
        <template #cell(Nachname)="data">
          <b-media class="align-items-center">
            <template #aside>
              <b-avatar
                size="40"
                :src="
                  data.item.User.avatar
                    ? $dbBaseUrl + data.item.User.avatar.formats.thumbnail.url
                    : ''
                "
                :text="`${data.item.Vorname.charAt()}${data.item.Nachname.charAt()}`"
                :variant="`light-primary`"
                :to="{
                  name: 'mitarbeiter-ansicht',
                  params: { id: data.item.id },
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'mitarbeiter-ansicht',
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ `${data.item.Vorname} ${data.item.Nachname}` }}
            </b-link>
            <small class="text-muted">{{ data.item.User.email }}</small>
          </b-media>
        </template>

        <!-- Column: Anstellung -->
        <template #cell(Anstellungsart)="data">
          {{ data.item.Anstellungsart }}
        </template>

        <!-- Column: Kenntnisse -->
        <template #cell(Kenntnisse)="data">
          {{
            data.item.Kenntnisse.Messmittel &&
            data.item.Kenntnisse.Qualitaetssicherung
              ? 'Messmittel, Qualitätssicherung'
              : data.item.Kenntnisse.Messmittel
              ? 'Messmittel'
              : data.item.Kenntnisse.Qualitaetssicherung
              ? 'Qualitätssicherung'
              : '-'
          }}
        </template>

        <!-- Column: Bewertung -->
        <template #cell(Bewertung)="data">
          <b-badge :variant="rating[1][data.item.Bewertung]">
            {{ data.item.Bewertung }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(Verfuegbar)="data">
          <b-badge
            :variant="
              status[1][
                data.item.Verfuegbar === 'Im_Auftrag'
                  ? 1
                  : data.item.Verfuegbar === 'Verfuegbar'
                  ? 2
                  : data.item.Verfuegbar === 'Krank'
                  ? 3
                  : 4
              ]
            "
          >
            {{
              status[0][
                data.item.Verfuegbar === 'Im_Auftrag'
                  ? 1
                  : data.item.Verfuegbar === 'Verfuegbar'
                  ? 2
                  : data.item.Verfuegbar === 'Krank'
                  ? 3
                  : 4
              ]
            }}
          </b-badge>
        </template>
      </b-table>
    </div>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Zeigt Mitarbeiter {{ dataMeta.from }} bis {{ dataMeta.to }} von
            {{ dataMeta.of }} Mitarbeitern</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BMedia,
  BLink,
  BPagination,
  BFormInput,
  BTooltip,
  VBModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import AddEmployeeModal from '@/layouts/components/modals/AddEmployeeModal.vue';
import DeleteEmployeeModal from '@/layouts/components/modals/DeleteEmployeeModal.vue';

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BMedia,
    BLink,
    BPagination,
    BFormInput,
    BTooltip,
    vSelect,
    AddEmployeeModal,
    DeleteEmployeeModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      totalRows: 0,
      tableKey: 1,
      filters: { searchQuery: '', statusFilter: '' },
      fields: [
        {
          key: 'Mitarbeiternummer',
          label: 'Mitarbeiternummer',
          sortable: true,
        },
        { key: 'Nachname', label: 'Name', sortable: true },
        { key: 'Anstellungsart', label: 'Anstellung', sortable: true },
        { key: 'Kenntnisse', label: 'Kenntnisse' },
        {
          key: 'Bewertung',
          label: 'Bewertung',
          sortable: true,
          class: 'text-center',
        },
        { key: 'Verfuegbar', label: 'Status', sortable: true },
        { key: 'Aktionen', label: 'Aktionen' },
      ],
      /* eslint-disable global-require */
      status: [
        {
          1: 'Im Auftrag',
          2: 'Verfügbar',
          3: 'Krank',
          4: '-',
        },
        {
          1: 'primary',
          2: 'success',
          3: 'danger',
          4: 'secondary',
        },
      ],
      rating: [
        {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
          6: '6',
        },
        {
          1: 'success',
          2: 'primary',
          3: 'info',
          4: 'secondary',
          5: 'warning',
          6: 'danger',
        },
      ],
    };
  },
  computed: {
    dataMeta() {
      const fromItems = this.perPage * (this.currentPage - 1) + 1;
      const toItems = this.perPage * (this.currentPage - 1) + this.perPage;

      return {
        from: fromItems,
        to:
          toItems < this.totalRows
            ? toItems
            : toItems - (toItems - this.totalRows),
        of: this.totalRows,
      };
    },
  },
  watch: {
    perPage(newValue, oldValue) {
      if (newValue !== oldValue) this.refetchTable();
    },
  },
  methods: {
    refetchTable() {
      this.tableKey += 1;
    },
    async employeeItemsProvider(ctx) {
      switch (true) {
        case ctx.sortBy === '':
          ctx.sortBy = 'Mitarbeiternummer';
          break;
        default:
          break;
      }

      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: ctx.currentPage,
            pageSize: this.perPage,
          },
          sort: [`${ctx.sortBy}:${ctx.sortDesc ? 'desc' : 'asc'}`],
          filters: {
            $or: [
              {
                Vorname: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
              {
                Nachname: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
              {
                Verfuegbar: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
              {
                Bewertung: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
              {
                Mitarbeiternummer: {
                  $containsi: ctx.filter.searchQuery,
                },
              },
            ],
            Verfuegbar: {
              $eq:
                ctx.filter.statusFilter === null
                  ? undefined
                  : ctx.filter.statusFilter.value,
            },
          },
          populate: [
            'Verfuegbarkeit',
            'Anschrift',
            'Kontakt',
            'Kenntnisse',
            'User.avatar',
          ],
        },
        {
          encodeValuesOnly: true,
        }
      );

      let employeeItems = [];

      await this.$http
        .get(`/mitarbeiters?${query}`)
        .then(response => {
          employeeItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      return employeeItems;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
#status-selection {
  min-width: 15rem;
}
.per-page-selector {
  min-width: 6rem;
}
.card.details-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
